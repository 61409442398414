'use client'
import React, { useRef, useState } from 'react';
import PropertyCard from '@components/PropertyCard';
import PropertyCardNew from '@components/PropertyCardNew';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import Link from 'next/link';
import LinkComponent from './LinkComponent';

const PropertyCardSlider = ({ title, properties, url, cardType, dictionary, lang }) => {
    return (
        <section className='pl-3 mt-3 2xl:w-8/12 pb-3 mx-auto border-b-[1px] border-b-forth'>
            {url ? (
                <LinkComponent href={`/${url}`}>
                    <h2 className='text-maincolor font-bold text-xl mb-2'>{title}</h2>
                </LinkComponent>
            ) : (
                <h2 className='text-maincolor font-bold text-xl mb-2'>{title}</h2>
            )}
            <div className='sm:block hidden'>
                <Swiper
                    slidesPerView={'auto'}
                    // spaceBetween={8}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Navigation]}
                    className=''
                // breakpoints={{
                //     // Modify the slidesPerView for different screen sizes
                //     640: {
                //         slidesPerView: 2.5, // For screens smaller than xl
                //     },
                //     1024: {
                //         slidesPerView: 3.5, // For screens equal to or larger than xl
                //     },
                //     1280: {
                //         slidesPerView: 3.5, // For screens equal to or larger than 2xl
                //     },
                //     1600: {
                //         slidesPerView: 5
                //     }
                // }}
                >
                    {properties.map((property, index) => (
                        <SwiperSlide key={index} className='w-[250px] property-swiper-slide'>
                            {cardType == 'new' ? (
                                <PropertyCardNew property={property} dictionary={dictionary.card} lang={lang} />
                            ) : (
                                <PropertyCard property={property} dictionary={dictionary.card} lang={lang} />
                            )

                            }
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {properties && (
                <div className='sm:hidden block'>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1.5}
                    >
                        {properties.map((property, index) => (
                            <SwiperSlide key={index}>
                                {cardType == 'new' ? (
                                    <PropertyCardNew property={property} dictionary={dictionary.card} lang={lang} />
                                ) : (
                                    <PropertyCard property={property} dictionary={dictionary.card} lang={lang} />
                                )

                                }
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
        </section>
    );
};

export default PropertyCardSlider;
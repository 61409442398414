'use client'
import React, { useState, useEffect, useRef } from 'react'
import { useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { AiOutlineLayout, AiFillHeart, AiOutlineHeart } from "react-icons/ai"
import { BiSolidBed, BiSolidBath } from "react-icons/bi"
import tailwindConfig from '@tailwind.config'
import { sendLike } from '@services/sendLike'
import CurrencySymbol from '@components/CurrencySymbol'
import LinkComponent from './LinkComponent'


const PropertyCard = ({ property, imageWidth = 300, imageHeight = 200, newLimit, isLast, dictionary, lang }) => {
    const [showLikeBtn, setShowLikeBtn] = useState(false)
    const [isLiked, setIsLiked] = useState(property.isLiked)
    const { data: session } = useSession()

    const cardRef = useRef();

    const handleLike = async () => {
        const isLike = await sendLike(property.id, session?.user?.email)
        setIsLiked((prevLike) => !prevLike)
        property.isLiked = !property.isLiked
    };

    useEffect(() => {
        if (!cardRef?.current) return;

        const observer = new IntersectionObserver(([entry]) => {
            if (isLast && entry.isIntersecting) {
                newLimit();
                // nextPage()
                observer.unobserve(entry.target);
            }
        });

        observer.observe(cardRef.current);
    }, [isLast]);

    useEffect(() => {
        if (session && session.user !== null) {
            setShowLikeBtn(true)
        }
    }, [])

    return (
        <div ref={cardRef} className='relative w-full'>
            <LinkComponent
                className='w-full'
                href={'/properties/' + property.slug}
                
            >
                <div className='relative w-[250px] h-[187.5px] rounded-md overflow-hidden'>
                    {(showLikeBtn == true && isLiked == false) && (
                        <div title="Left-click on this icon to save this property" onClick={handleLike} className='absolute flex flex-row flex-wrap top-1 right-1 z-40 h-8 w-8 justify-center items-center border-[1px] border-secondary rounded-md p-1'>
                            <AiOutlineHeart
                                size={24}
                                color={tailwindConfig.theme.extend.colors.secondary}
                            />
                        </div>
                    )}
                    {(showLikeBtn == true && isLiked == true) && (
                        <div title="Left-click to unlike this property" onClick={handleLike} className='absolute flex flex-row flex-wrap top-1 right-1 z-40 h-8 w-8 justify-center items-center border-[1px] border-third rounded-md p-1'>
                            <AiFillHeart
                                size={24}
                                color={tailwindConfig.theme.extend.colors.third}
                            />
                        </div>
                    )}
                    <Image
                        placeholder="blur"
                        blurDataURL={property.blur}
                        // className='object-fit'
                        className='object-cover'
                        src={property.cover}
                        // width={imageWidth}
                        // height={imageHeight}
                        fill={true}
                        alt={property.slug}
                    />
                    {property.publicTransport ? (
                        <div className='absolute flex flex-row flex-wrap bottom-0 left-0'>
                            {property.publicTransport.map((transport, index) => (
                                <div key={index} style={{ backgroundColor: transport.colorCode }} className={`rounded-sm px-1 text-light text-sm ml-1 mb-1`}>{transport.type.toUpperCase()} {transport.transportName?.[lang] ? transport.transportName[lang] : transport.stationName}</div>
                            ))}
                        </div>
                    ) : null}
                </div>
                <div className='absolute top-2 bg-maincolor text-light p-1 text-md rounded-r-md z-10'><h3>{property.category}{dictionary.for}{property.saleType}</h3></div>
                <div className=''>
                    <div className='text-xl font-semibold text-third'><CurrencySymbol lang={lang} />{property.price}{property.saleType === 'rent' ? `/${dictionary.month}` : ''}</div>
                    {property.category == 'Land' ? (
                        <div className='flex flex-row'>
                            <div className='flex flex-row items-center'>
                                <span className='mr-1 text-maincolor'>{property.livingArea} {dictionary.sqm}</span>
                                <AiOutlineLayout
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='flex flex-row'>
                            {(property.bedrooms && property.bedrooms > 0) &&
                                <div className='flex flex-row items-center mr-2'>
                                    <span className='mr-1 text-maincolor'>{property.bedrooms}</span>
                                    <BiSolidBed
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                    />
                                </div>
                            }
                            {(property.bathrooms && property.bathrooms > 0) &&
                                <div className='flex flex-row items-center mr-2'>
                                    <span className='mr-1 text-maincolor'>{property.bathrooms}</span>
                                    <BiSolidBath
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                    />
                                </div>
                            }
                            <div className='flex flex-row items-center'>
                                <span className='mr-1 text-maincolor'>{property.livingArea} {dictionary.sqm}</span>
                                <AiOutlineLayout
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                            </div>
                        </div>
                    )
                    }

                    <div>
                        <p className='text-mainhover'>{property.districts[0]?.subdistrictName?.[lang] ? property.districts[0].subdistrictName[lang] : property.districts[0]?.subdistrict}, {property.location}</p>
                    </div>
                </div>
            </LinkComponent >
        </div>
    )
}

export default PropertyCard

import { getCookie, setCookie } from 'cookies-next';
const baseUrl = process.env.NEXT_PUBLIC_BASE_URL


export const sendLike = async (propertyId) => {

    const id = getCookie('id')

    // console.log(id)
    // let id = idCookie.value

    if (id !== undefined) {
        const data = {
            propertyId,
            id,
        };


        const url = `${baseUrl}/likes`

        const headers = {
            'apikey': process.env.NEXT_PUBLIC_RSTBASE_API_KEY,
            'Content-Type': 'application/json'
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            cache: 'no-store',
            body: JSON.stringify(data)
        })

        const result = await response.json()
        return result
    } else {
        let likes = getCookie('likes')
        if (likes == undefined) {
            const likeArray = []
            likeArray.push(propertyId)
            setCookie('likes', JSON.stringify(likeArray))
        } else {
            let likeArray = JSON.parse(likes)
            if (likeArray.includes(propertyId)) {
                console.log('includes property id')
                console.log(likeArray)

                const newLikesArray = likeArray.filter(function (item) {
                    return item !== propertyId
                })
                setCookie('likes', JSON.stringify(newLikesArray))
            } else {
                console.log('doesnt include property id')
                console.log(likeArray)

                likeArray.push(propertyId)
                setCookie('likes', JSON.stringify(likeArray))
            }

        }
        return
    }
}